var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pt-8 pb-12"},[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('router-link',{attrs:{"to":{name: 'Libraries'}}},[_c('cv-button',{staticClass:"whitespace-no-wrap",attrs:{"kind":"secondary"}},[_vm._v(" Voltar para Lista de Biblioteca ")])],1)],1),_c('div',{staticClass:"flex justify-center my-8"},[_c('ValidationObserver',{staticClass:"w-full px-3 lg:w-1/2 xl:w-1/3 list-mt-6",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('div',{staticClass:"text-3xl mb-3"},[_vm._v(" Novo Material de Apoio ")]),_c('ValidationProvider',{staticClass:"pt-4",attrs:{"name":"title","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Título","invalid-message":errors[0],"placeholder":"Características da Artrose no Quadril Esquerdo"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"pt-4",attrs:{"name":"type","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-dropdown',{class:{ 'mb-3': errors.length },attrs:{"invalid-message":errors[0],"label":"Tipo"},model:{value:(_vm.form.typeId),callback:function ($$v) {_vm.$set(_vm.form, "typeId", $$v)},expression:"form.typeId"}},_vm._l((_vm.types),function(type){return _c('cv-dropdown-item',{key:type.id,attrs:{"value":type.id}},[_vm._v(" "+_vm._s(type.name)+" ")])}),1)]}}],null,true)}),(_vm.selectedType.name !== 'Link')?_c('ValidationProvider',{staticClass:"mt-4",attrs:{"tag":"div","rules":"required","name":"upload"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-file-uploader',{attrs:{"accept":_vm.acceptedFormats,"clear-on-reselect":"","initial-state-uploading":"","removable":"","helper-text":"Upload"},on:{"input":_vm.onUpload},model:{value:(_vm.uploader),callback:function ($$v) {_vm.uploader=$$v},expression:"uploader"}},[_c('template',{slot:"drop-target"},[_vm._v(" Clique para fazer o upload ")])],2),(errors.length)?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione um arquivo ")]):_vm._e()]}}],null,true)}):_vm._e(),_c('cv-text-input',{staticClass:"pt-4",attrs:{"label":"Link (opcional)","placeholder":"https://youtu.be/12345asdf","helper-text":"Digite o link inteiro para o vídeo no Youtube ou Vimeo"},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}}),_c('cv-text-area',{staticClass:"pt-4",attrs:{"label":"Descrição (opcional)","rows":"5"},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}}),_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}),(_vm.notification.open)?_c('cv-inline-notification',{attrs:{"kind":_vm.notification.kind,"title":_vm.notification.title,"sub-title":_vm.notification.message,"close-aria-label":"Fechar","low-contrast":false},on:{"close":function($event){_vm.notification.open = false}}}):_vm._e(),_c('div',{staticClass:"mt-4"},[(_vm.done)?_c('cv-button',{on:{"click":function($event){_vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }