


















































































































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'

import NotificationMixin from '@/mixins/notificationMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    NotifyOnFailed,
    PasswordConfirmField
  }
})
export default class LibrariesCreate extends Mixins(NotificationMixin, FlashMessageMixin, ValidatorConfigMixin) {
  form: Record<string, any>= {
    title: '',
    typeId: '',
    link: '',
    file: '',
    notes: '',
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  types = []

  created () {
    axios.get('library/type')
      .then(response => {
        this.types = response.data.data
      })
  }

  done = true

  submit () {
    this.done = false
    if (this.selectedType.name === 'Link') {
      this.form.file = ''
    }

    axios.post('library', snakeCaseKeys(this.form))
      .then(() => {
        this.$router.push({ name: 'Libraries' }, () => {
          const flashMessage = {
            message: 'Material de Apoio enviado com sucesso.',
            isSuccess: true
          }
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  get selectedType () {
    return this.types.find((type: MyDocumentType) => type.id === this.form.typeId) || { name: '' }
  }

  uploader: UploaderArray = []
  filesizeMB = 0

  acceptedFormats = ['.epub', '.doc', '.pdf', '.rtf', '.xls', '.xlsx', '.docx', '.ppt', '.pptx', '.gif', '.jpg', '.png', '.txt', '.csv']

  async onUpload ($e: any) {
    const filename = $e.target.files[0].name
    const fileExt = filename.split(/(?=\.)/g).slice(-1).pop().toLowerCase()
    const base64file = await this.toBase64($e.target.files[0]) as string

    this.filesizeMB = new Blob([base64file]).size / 10 ** 6
    const isAcceptedFormat = this.acceptedFormats.includes(fileExt)

    if (isAcceptedFormat && this.filesizeMB < 50) {
      this.uploader[0].state = 'complete'
      this.form.file = base64file
    } else {
      this.uploader[0].state = ''
      this.uploader[0].invalidMessage = isAcceptedFormat ? 'Arquivo excede 50 MB' : 'Formato não suportado'
      this.uploader[0].invalidMessageTitle = 'Erro'
    }
  }

  toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  });
}
